var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import BaseTooltip from '../BaseTooltip';
import { IconButton, Button, useMediaQuery } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { StyledBadgeV7 } from '../StyledBadge';
import React from 'react';
import clsx from 'clsx';
import { TERM_STATUS } from '../../stores';
import { priceValue } from '../PriceComponent';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useSettingsStyles } from './styles';
import { withPaymentTermChannelsStore } from './store';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';
import { TextOverflow } from '../TextOverflow';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiBadge-badge': {
            right: 'initial',
            top: 'initial',
        },
    },
    rootV2: {
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
        },
    },
}); });
function UserSettingsPaymentTermIcon(_a) {
    var _b;
    var handleClickOpen = _a.handleClickOpen, v1 = _a.v1, v2 = _a.v2, props = __rest(_a, ["handleClickOpen", "v1", "v2"]);
    var langApp = useTranslation(KEYS.APP);
    var isSmall = useMediaQuery(theme.breakpoints.down('md'));
    var classesS = useStyles();
    var classes = useSettingsStyles();
    var title = langApp.t('app_payment_terms');
    var paymentTerm = props.store.accountStore.currentCompany.paymentTerm;
    var tooltipTitle = [
        title,
        (paymentTerm === null || paymentTerm === void 0 ? void 0 : paymentTerm.status) === TERM_STATUS.APPROVED
            ? "" + paymentTerm.term + langApp.t('app_time_days') + ", " + priceValue(paymentTerm)
            : '',
    ]
        .filter(function (e) { return e; })
        .join('. ');
    var text = (React.createElement(Button, { variant: "outlined", color: "primary", onClick: handleClickOpen, style: { textTransform: 'none', border: 'none', padding: theme.spacing(0.1, 1), minWidth: 0 } },
        React.createElement(TextOverflow, null, [langApp.t('app_payment_terms'), ": " + ((paymentTerm === null || paymentTerm === void 0 ? void 0 : paymentTerm.term) || 30) + langApp.t('app_time_days_short')]
            .filter(function (e) { return e; })
            .join(''))));
    var icon = (React.createElement(BaseTooltip, { title: tooltipTitle, key: [(paymentTerm === null || paymentTerm === void 0 ? void 0 : paymentTerm.updatedAt) || 1, props.store.accountStore.currentCompany.updatedAt].join('_') }, v2 && !isSmall ? (text) : (React.createElement(IconButton, { className: classes.root, style: __assign({}, (props.v1 && { padding: 0 })), "aria-label": "menu", onClick: handleClickOpen, color: 'primary' },
        React.createElement(AccountBalanceWalletIcon, null)))));
    if (props.paymentTermChannelStore.state.totalUnread) {
        icon = (React.createElement(StyledBadgeV7, { className: clsx((_b = {}, _b[classesS.root] = props.v1, _b[classesS.rootV2] = v2 && !isSmall, _b)), badgeContent: props.paymentTermChannelStore.state.totalUnread }, icon));
    }
    return icon;
}
export default inject('store')(withPaymentTermChannelsStore(observer(UserSettingsPaymentTermIcon)));
